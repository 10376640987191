import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import BottomCta from '../components/bottom-cta'

// Load Page Content
import contentEn from '../../content/pages/solutions.en.yaml'
import contentId from '../../content/pages/solutions.id.yaml'
import OtherProducts from '../components/other-products'
import StudyCaseSlider, { AllStudyCaseType } from '../components/study-case'
import SEO from '../components/seo'

export const query = graphql`
  query SolutionsQuery($language: String!) {
    locales: allLocale(filter: { ns: { in: ["links", "products"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    SolutionsStudyCase: allStudyCasesVoice {
      edges {
        node {
          id
          languages {
            id
            description
            locale
            logo
            title
          }
        }
      }
    }
    heroFigure: file(relativePath: { eq: "images/solutions/figure-hero.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 95)
      }
    }
    pointIcon: file(relativePath: { eq: "images/solutions/icon-point.svg" }) {
      id
      publicURL
    }
    increaseSalesIcon: file(relativePath: { eq: "images/solutions/icon-increase-sales.svg" }) {
      id
      publicURL
    }
    improveCSIcon: file(relativePath: { eq: "images/solutions/icon-improve-cs.svg" }) {
      id
      publicURL
    }
    dataEntryIcon: file(relativePath: { eq: "images/solutions/icon-data-entry.svg" }) {
      id
      publicURL
    }
    collectionIcon: file(relativePath: { eq: "images/solutions/icon-collection.svg" }) {
      id
      publicURL
    }
    whyJasnita01Icon: file(relativePath: { eq: "images/business-outsourcing/icon-why-jasnita-01.svg" }) {
      id
      publicURL
    }
    whyJasnita02Icon: file(relativePath: { eq: "images/business-outsourcing/icon-why-jasnita-02.svg" }) {
      id
      publicURL
    }
    whyJasnita03Icon: file(relativePath: { eq: "images/business-outsourcing/icon-why-jasnita-03.svg" }) {
      id
      publicURL
    }
  }
`

interface SolutionsPagePropsType {
  data: {
    SolutionsStudyCase: AllStudyCaseType
    pointIcon: PublicUrlType
    heroFigure: IGatsbyImageData
    focusGoalsIcon: PublicUrlType
    increaseSalesIcon: PublicUrlType
    improveCSIcon: PublicUrlType
    dataEntryIcon: PublicUrlType
    collectionIcon: PublicUrlType
    saveExtraIcon: PublicUrlType
    weManageIcon: PublicUrlType
    whyJasnita01Icon: PublicUrlType
    whyJasnita02Icon: PublicUrlType
    whyJasnita03Icon: PublicUrlType
    whyJasnitaFigure: IGatsbyImageData
  }
}

const SolutionsPage = (props: SolutionsPagePropsType): JSX.Element => {
  const { t, i18n } = useTranslation()
  const [content, setContent] = useState(contentEn)
  const {
    SolutionsStudyCase,
    pointIcon,

    focusGoalsIcon,
    saveExtraIcon,
    weManageIcon,
    increaseSalesIcon,
    improveCSIcon,
    dataEntryIcon,
    collectionIcon,
    whyJasnita01Icon,
    whyJasnita02Icon,
    whyJasnita03Icon,
  } = props.data

  const heroFigure = getImage(props.data.heroFigure)
  const whyJasnitaFigure = getImage(props.data.whyJasnitaFigure)

  useEffect(() => {
    switch (i18n.language) {
      case 'id':
        setContent(contentId)
        break
      case 'en':
        setContent(contentEn)
        break
      default:
        setContent(contentEn)
        break
    }
  }, [i18n.language])

  return (
    <>
      <SEO title={content.title} description={content.description} lang={i18n.language}></SEO>
      {/* Start Hero Section */}
      <section className="uk-light">
        <div
          className="uk-section uk-background-norepeat uk-background-cover tm-business-oursourcing-hero-background uk-flex uk-flex-middle"
          uk-height-viewport=""
          uk-img=""
          style={{ minHeight: 'calc(100vh)' }}
        >
          <div className="uk-width-1-1">
            <div className="uk-container">
              <div className="uk-flex uk-flex-between" uk-grid="">
                <div className="uk-width-1-2@m uk-flex uk-flex-middle">
                  <div className="uk-animation-slide-top-medium uk-text-center uk-text-left@m">
                    {/* <img className="uk-hidden@m" width="125" alt="" uk-img={logo.publicURL} /> */}
                    <h1 className="uk-heading-small">{content.title}</h1>
                    <p className="uk-text-lead">{content.description}</p>
                  </div>
                </div>
                <div className="uk-width-1-2@m uk-flex uk-flex-right uk-visible@m">
                  {heroFigure && <GatsbyImage image={heroFigure} alt="Business Outsourcing" />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Hero Section */}

      <section className="uk-section">
        <div className="uk-with-1-1">
          <div className="uk-container">
            <h3 className="uk-text-center">{content.by_objective.title}</h3>
            <div className="uk-grid-large uk-child-width-1-2@m uk-margin-large-top uk-grid-match" uk-grid="">
              <div className="uk-position-relative">
                <div className="tm-solution-card">
                  <div className="uk-card uk-card-default uk-card-body uk-height-1-1">
                    <div className="uk-flex">
                      <img
                        className="uk-position-absolute"
                        style={{ top: -45 }}
                        src={increaseSalesIcon.publicURL}
                        alt=""
                        uk-img=""
                      />
                      <h4 className="uk-margin-remove-top" style={{ marginLeft: 150 }}>
                        {content.by_objective.increase_sales.title}
                      </h4>
                    </div>

                    <div className="uk-child-width-1-3@m" uk-grid="">
                      {content.by_objective.increase_sales.lists.map((title: string, index: number) => (
                        <div key={index} className="uk-flex uk-flex-middle">
                          <img src={pointIcon.publicURL} alt="" uk-img="" width={50} />
                          <h5 className="uk-margin-remove-top uk-margin-left">{title}</h5>
                        </div>
                      ))}
                    </div>
                    <p>{content.by_objective.increase_sales.description}</p>
                    <div className="uk-text-right">
                      <a href="#">Read more</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="uk-position-relative">
                <div className="tm-solution-card">
                  <div className="uk-card uk-card-default uk-card-body uk-height-1-1">
                    <div className="uk-flex">
                      <img
                        className="uk-position-absolute"
                        style={{ top: -45 }}
                        src={improveCSIcon.publicURL}
                        alt=""
                        uk-img=""
                      />
                      <h4 className="uk-margin-remove-top" style={{ marginLeft: 150 }}>
                        {content.by_objective.improve_cs.title}
                      </h4>
                    </div>

                    <div className="uk-child-width-1-2@m" uk-grid="">
                      {content.by_objective.improve_cs.lists.map((title: string, index: number) => (
                        <div key={index} className="uk-flex uk-flex-middle">
                          <img src={pointIcon.publicURL} alt="" uk-img="" width={50} />
                          <h5 className="uk-margin-remove-top uk-margin-left">{title}</h5>
                        </div>
                      ))}
                    </div>
                    <p>{content.by_objective.improve_cs.description}</p>
                    <div className="uk-text-right">
                      <a href="#">Read more</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="uk-position-relative">
                <div className="tm-solution-card">
                  <div className="uk-card uk-card-default uk-card-body uk-height-1-1">
                    <div className="uk-flex">
                      <img
                        className="uk-position-absolute"
                        style={{ top: -45 }}
                        src={dataEntryIcon.publicURL}
                        alt=""
                        uk-img=""
                      />
                      <h4 className="uk-margin-remove-top" style={{ marginLeft: 150 }}>
                        {content.by_objective.data_entry.title}
                      </h4>
                    </div>

                    <div className="uk-child-width-1-2@m" uk-grid="">
                      {content.by_objective.data_entry.lists.map((title: string, index: number) => (
                        <div key={index} className="uk-flex uk-flex-middle">
                          <img src={pointIcon.publicURL} alt="" uk-img="" width={50} />
                          <h5 className="uk-margin-remove-top uk-margin-left">{title}</h5>
                        </div>
                      ))}
                    </div>
                    <p>{content.by_objective.data_entry.description}</p>
                    <div className="uk-text-right">
                      <a href="#">Read more</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="uk-position-relative">
                <div className="tm-solution-card">
                  <div className="uk-card uk-card-default uk-card-body uk-height-1-1">
                    <div className="uk-flex">
                      <img
                        className="uk-position-absolute"
                        style={{ top: -45 }}
                        src={collectionIcon.publicURL}
                        alt=""
                        uk-img=""
                      />
                      <h4 className="uk-margin-remove-top" style={{ marginLeft: 150 }}>
                        {content.by_objective.collection.title}
                      </h4>
                    </div>

                    <div className="uk-child-width-1-3@m" uk-grid="">
                      {content.by_objective.collection.lists.map((title: string, index: number) => (
                        <div key={index} className="uk-flex uk-flex-middle">
                          <img src={pointIcon.publicURL} alt="" uk-img="" width={50} />
                          <h5 className="uk-margin-remove-top uk-margin-left">{title}</h5>
                        </div>
                      ))}
                    </div>
                    <p>{content.by_objective.collection.description}</p>
                    <div className="uk-text-right">
                      <a href="#">Read more</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section">
        <div className="uk-with-1-1">
          <div className="uk-container">
            <h3 className="uk-text-center">{content.by_industries.title}</h3>
            <div uk-grid="">
              <div className="uk-width-1-3@m uk-flex uk-flex-column" uk-switcher="">
                {content.by_industries.lists.map((list: { title: string }, index: number) => (
                  <div key={index}>
                    <button className="uk-button uk-button-default uk-width-1-1 uk-margin-bottom" type="button">
                      {list.title}
                    </button>
                  </div>
                ))}
              </div>

              <ul className="uk-width-expand@m uk-switcher uk-margin">
                {content.by_industries.lists.map((point: { title: string; lists: [] }) => (
                  <li key={point.title}>
                    <div className="uk-child-width-1-3@m" uk-grid="">
                      {point.lists.map((title: string) => (
                        <div key={title}>
                          <img src={pointIcon.publicURL} alt="" uk-img="" width={50} />
                          <h5 className="uk-margin-top">{title}</h5>
                        </div>
                      ))}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      <BottomCta color="#0C73D2" />
      {/* <OtherProducts products={content.other_product} /> */}
    </>
  )
}

export default SolutionsPage
